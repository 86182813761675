(function ($) {
    var win = window, xhrs = [
        function () {
            return new XMLHttpRequest();
        },
        function () {
            return new ActiveXObject("Microsoft.XMLHTTP");
        },
        function () {
            return new ActiveXObject("MSXML2.XMLHTTP.3.0");
        },
        function () {
            return new ActiveXObject("MSXML2.XMLHTTP");
        }
    ],
            _xhrf = null;
    var hasOwnProperty = Object.prototype.hasOwnProperty,
            nativeForEach = Array.prototype.forEach;
    var _each = function (o, fn, ctx) {
        if (o == null)
            return;
        if (nativeForEach && o.forEach === nativeForEach)
            o.forEach(fn, ctx);
        else if (o.length === +o.length) {
            for (var i = 0, l = o.length; i < l; i++)
                if (i in o && fn.call(ctx, o[i], i, o) === breaker)
                    return;
        } else {
            for (var key in o)
                if (hasOwnProperty.call(o, key))
                    if (fn.call(ctx, o[key], key, o) === breaker)
                        return;
        }
    };
    var _extend = function (o) {
        _each(Array.prototype.slice.call(arguments, 1), function (a) {
            for (var p in a)
                if (a[p] !== void 0)
                    o[p] = a[p];
        });
        return o;
    };

    $.xhr = function () {
        if (_xhrf != null)
            return _xhrf();
        for (var i = 0, l = xhrs.length; i < l; i++) {
            try {
                var f = xhrs[i], req = f();
                if (req != null) {
                    _xhrf = f;
                    return req;
                }
            } catch (e) {
                continue;
            }
        }
        return function () { };
    };
    $._xhrResp = function (xhr, o) {
        switch (o.dataType) {
            case 'xml':
                return xhr.responseXML;
            case 'json':
                return (win.JSON ? JSON.parse(xhr.responseText) : eval(xhr.responseText));
            case 'script':
                return eval(xhr.responseText);
            case 'html':
                return xhr.responseText;
        }
        switch (xhr.getResponseHeader("Content-Type").split(";")[0]) {
            case "text/xml":
                return xhr.responseXML;
            case "text/json":
            case "application/json":
            case "text/javascript":
            case "application/javascript":
            case "application/x-javascript":
                return (win.JSON ? JSON.parse(xhr.responseText) : eval(xhr.responseText));
            default:
                return xhr.responseText;
        }
    };
    $._formData = function (obj, prefix) {
        var str = [],
                p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p,
                        v = obj[p];
                str.push((v !== null && typeof v === "object") ?
                        $._formData(v, k) :
                        encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    };
    $.post = function (url, data, callback) {
        $.ajax({
            url: url,
            data: data,
            method: 'POST',
            dataType: 'json',
            success: callback
        });
    };
    $.get = function (url, data, callback) {
        $.ajax({
            url: url,
            data: data,
            method: 'GET',
            dataType: 'json',
            success: callback
        });
    };
    $.put = function (url, data, callback) {
        $.ajax({
            url: url,
            data: data,
            method: 'PUT',
            dataType: 'json',
            success: callback
        });
    };
    $.getJSON = function (url, data, callback) {
        $.ajax({
            url: url,
            data: data,
            method: 'GET',
            dataType: 'json',
            success: callback
        });
    };
    $.ajax = function (o1, o2) {
        var xhr = $.xhr(), timer, n = 0, o = {}, data = '';
        if (typeof (o1) === 'string' && typeof (o2) === 'object') {
            o = o2;
            o['url'] = o1;
        } else {
            o = o1;
        }
        o = _extend({userAgent: "XMLHttpRequest", lang: "en", method: "GET", data: null, dataType: null, contentType: "application/x-www-form-urlencoded"}, o);
        if (o.timeout)
            timer = setTimeout(function () {
                xhr.abort();
                if (o.timeoutFn)
                    o.timeoutFn(o.url);
            }, o.timeout);
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
                if (timer)
                    clearTimeout(timer);
                if (xhr.status < 300) {
                    if (o.success)
                        o.success($._xhrResp(xhr, o));
                } else if (o.error)
                    o.error(xhr, xhr.status, xhr.statusText);
                if (o.complete)
                    o.complete(xhr, xhr.statusText);
            } else if (o.progress)
                o.progress(++n);
        };
        var url = o.url, data = null;
        o.method = o.method.toUpperCase();
        var isPost = o.method == "POST" || o.method == "PUT";
        if (isPost) {
            if (o.data instanceof FormData) {
                data = o.data;
            } else {
                data = $._formData(o.data);
            }
        }
        if (!isPost && o.data) {
            url += "?" + $._formData(o.data);
        }
        xhr.open(o.method, url);
        if(o.contentType) {
            xhr.setRequestHeader("Content-Type", o.contentType);
        }
        xhr.send(data);
    };
    $.getScript = function (source, callback) {
        var script = document.createElement('script');
        var prior = document.getElementsByTagName('script')[0];
        script.async = 1;

        script.onload = script.onreadystatechange = function (_, isAbort) {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = script.onreadystatechange = null;
                script = undefined;

                if (!isAbort && callback)
                    setTimeout(callback, 0);
            }
        };

        script.src = source;
        prior.parentNode.insertBefore(script, prior);
    };
})(window.jQuery);