var submitedFormSelector = '';
var activeFormSelector = '';
function irisValidation(element, settings) {
    if (!$(element).attr('id')) {
        console.error('From should have an ID');
    }

    function isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    function mergeDeep(target, ...sources) {
        if (!sources.length)
            return target;
        const source = sources.shift();

        if (isObject(target) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!target[key])
                        Object.assign(target, {[key]: {}});
                    mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, {[key]: source[key]});
                }
            }
        }

        return mergeDeep(target, ...sources);
    }


    var iris_validator = {
        defaultSettings: {
            url: '',
            formInvalidClass: 'has-error', // Class which adds to element container if it has error
            formValidClass: 'has-success', // Class which adds to element container if it has no error
            formProgressClass: 'has-progress', // Class which adds to element container if it has progress
            formErrorClass: 'help-block with-errors', // Class of element with error description
            formErrorTag: 'div', // tag of elemrnt error description
            formParentClass: 'form-group', // Class of element container
            formElementClass: 'form-control', // Class of element
            focusFirstInput: true, // Class of element container
            errorMessages: {
                required: 'Please fill out this field.',
                firstNameLong: 'First Name is too long',
                firstNameFormat: 'Only letters and digits are allowed in First Name',
                lastNameLong: 'Last Name is too long',
                lastNameFormat: 'Only letters and digits are allowed in Last Name',
                emailFormat: 'Please enter a valid email address',
                phoneFormat: 'Phone number must contain 10 digits',
                companyNameLong: 'Company Name is too long',
                companyNameFormat: 'Your Company Name has invalid format',
                connectionError: 'Server connection error. Please try again later.', // Text of error when we have some connection errors
            },
            filters: {},
            validators: {},
            validateByTimeout: [],
            success: function (data) {
                window.location = data.url;
            },
        },
        formSelector: '#' + $(element).attr('id'), // Text of error for required field
        formSubmitButton: '#' + $(element).find('button[type="submit"]').attr('id'), // Text of error for required field
        tokenSelector: '#' + $(element).attr('id') + ' .g-recaptcha-response', // Text of error for required field
        validateDomainTimeout: null,
        validateCodeTimeout: null,
        phoneValidationLock: false,
        validateByTimeout: [],
        validateTimeouts: {},
        validateRequiredTimeouts: {},
        recaptchaAdded: false,
        validatedDomains: [],
        demoTime: false,
        errors: {},
        baseFilters = {
            phoneMask = (element) => {
                var value = $(element).val();
                $(element).val(iris_validator.createMask(iris_validator.destroyMask(value)));
            }
        },
        baseValidators: {
            firstName: function (element) {
                var value = $(element).val();
                var regex = /^[a-zA-Z0-9\s\.\-\`\'\’]*$/gm;
                iris_validator.removeError(element);
                if (value.length > 0) {
                    if (value.length > 30) {
                        iris_validator.showError(element, iris_validator.errorMessages.firstNameLong, '_validator');
                        return;
                    } else if (regex.exec(value) === null) {
                        iris_validator.showError(element, iris_validator.errorMessages.firstNameFormat, '_validator');
                        return;
                    }
                }
            },
            lastName: function (element) {
                var value = $(element).val();
                var regex = /^[a-zA-Z0-9\s\.\-\`\'\’]*$/gm;
                iris_validator.removeError(element);
                if (value.length > 0) {
                    if (value.length > 30) {
                        iris_validator.showError(element, iris_validator.errorMessages.lastNameLong, '_validator');
                        return;
                    } else if (regex.exec(value) === null) {
                        iris_validator.showError(element, iris_validator.errorMessages.lastNameFormat, '_validator');
                        return;
                    }
                }
            },
            email: function (element) {
                var value = $(element).val();
                var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9]+((\-?[a-zA-Z0-9]{1,})|(\.?[a-zA-Z0-9]+\-?[a-zA-Z0-9]+))*(\.{1}[a-zA-Z]{2,})$/gm;
                iris_validator.removeError(element, '_validator');
                if (value.length > 0) {
                    if (regex.exec(value) === null) {
                        iris_validator.showError(element, iris_validator.errorMessages.emailFormat, '_validator');
                        return;
                    }
                }
            },
            phone: function (element) {
                var value = $(element).val();
                iris_validator.removeError(element, '_validator');
                $(element).val(iris_validator.createMask(iris_validator.destroyMask(value)));
                if (iris_validator.destroyMask(value).length > 0 && iris_validator.destroyMask(value).length < 10) {
                    iris_validator.showError(element, iris_validator.errorMessages.phoneFormat, '_validator');
                }
            },
            companyName: function (element) {
                var value = $(element).val();
                var regex = /^[A-Za-z0-9 \"“”'‘’()[\]{}<>«»\\\/?!&@£$€¥.,-;:]{3}[*=#%+A-Za-z0-9 \"“”'‘’()[\]{}<>«»\\\/?!&@£$€¥.,-:;]{0,157}$/gm;
                iris_validator.removeError(element);
                if (value.length > 0) {
                    if (value.length > 157) {
                        iris_validator.showError(element, iris_validator.errorMessages.companyNameLong, '_validator');
                        return;
                    } else if (regex.exec(value) === null) {
                        iris_validator.showError(element, iris_validator.errorMessages.companyNameFormat, '_validator');
                        return;
                    }
                }
            },
        },
        filters: {
            __required: function (element) {
                iris_validator.removeError(element, '_required');
            },
            __all: function (element) {
                iris_validator.removeError(element, '_validator');
                activeFormSelector = iris_validator.formSelector;
            },

        },
        validators: {
            __required: function (element) {
                iris_validator.removeError(element, '_required');
                if ($(element).val() == '') {
                    iris_validator.showError(element, iris_validator.errorMessages.required, '_required');
                }
            },
        },
        showError: function (element, errorText, errorPrefix) {
            var errorPrefix = errorPrefix || '';
            var parent = $(element).parents(iris_validator.classToSelector(iris_validator.formParentClass));
            $(parent).find('[data-marker="' + $(element).attr('name') + errorPrefix + '"]').remove();
            $(parent).addClass(iris_validator.formInvalidClass);
            $(parent).removeClass(iris_validator.formValidClass);
            $(parent).append(iris_validator.errorBlock(errorText, $(element).attr('name') + errorPrefix));
            iris_validator.errors[$(element).attr('name') + errorPrefix] = true;
            iris_validator.checkErrors();
        },
        showSuccess: function (element) {
            var parent = $(element).parents(iris_validator.classToSelector(iris_validator.formParentClass));
            $(parent).removeClass(iris_validator.formValidClass);
            var availibleSuffixes = ['_required', '_validator', '_error'];
            var name = $(element).attr('name');
            var error = false;
            if (typeof (iris_validator.errors[name]) !== 'undefined') {
                error = true;
            }
            for (var i in availibleSuffixes) {
                if (typeof (iris_validator.errors[name + availibleSuffixes[i]]) !== 'undefined') {
                    error = true;
                }
            }
            if (!error) {
                $(parent).removeClass(iris_validator.formInvalidClass);
                if (!$(element).hasClass(iris_validator.formProgressClass)) {
                    $(parent).addClass(iris_validator.formValidClass);
                }
            }
            iris_validator.checkErrors();
        },
        removeError: function (element, errorPrefix) {
            var errorPrefix = errorPrefix || '';
            var parent = $(element).parents(iris_validator.classToSelector(iris_validator.formParentClass));
            $(parent).find('[data-marker="' + $(element).attr('name') + errorPrefix + '"]').remove();
            delete iris_validator.errors[$(element).attr('name') + errorPrefix];
            iris_validator.showSuccess(element);
        },
        errorBlock: function (text, marker) {
            return '<' + iris_validator.formErrorTag + ' class="' + iris_validator.formErrorClass + '" data-marker="' + marker + '">' + text + '</' + iris_validator.formErrorTag + '>';
        },
        classToSelector: function (className) {
            return '.' + className.replace(' ', '.');
        },
        createMask: function (string) {
            string = string.replace(/(\d{10})/, "$1");
            if (string.length > 3) {
                string = string.substr(0, 3) + '-' + string.substr(3);
            }
            if (string.length > 7) {
                string = string.substr(0, 7) + '-' + string.substr(7);
            }
            return string;
        },
        destroyMask: function (string) {
            return string.replace(/\D/g, '').substring(0, 10);
        },
        checkErrors: function () {
            if (Object.keys(iris_validator.errors).length > 0) {
                iris_validator.disableSubmit();
            } else {
                iris_validator.enableSubmit();
            }
        },
        applySettings: function (settings) {
            mergeDeep(this, iris_validator.defaultSettings, settings);
        },
        applyBaseFunctions: function () {
            for (var key in iris_validator.filters) {
                if (typeof (iris_validator.filters[key]) === 'string' && iris_validator.baseFilters[iris_validator.filters[key]]) {
                    iris_validator.filters[key] = iris_validator.baseFilters[iris_validator.filters[key]];
                }
            }
            for (var key in iris_validator.validators) {
                if (typeof (iris_validator.validators[key]) === 'string' && iris_validator.baseValidators[iris_validator.validators[key]]) {
                    iris_validator.validators[key] = iris_validator.baseValidators[iris_validator.validators[key]];
                }
            }
        },
        serialize: function () {
            iris_validator.formArray = $(iris_validator.formSelector).serializeArray();
            $.each($(iris_validator.formSelector).find('input[type="file"]'), function (i, tag) {
                iris_validator.formArray.push({name: tag.name, value: null});
            });
        },
        applyFiltersValidators: function () {
            iris_validator.serialize();
            for (var i in iris_validator.formArray) {
                var elementName = iris_validator.formArray[i]['name'];
                var elementSelector = iris_validator.formSelector + ' [name="' + elementName + '"]';
                var filterActions = $(elementSelector).prop("tagName") == 'SELECT' ? 'keypress keyup paste change click' : 'keypress keyup paste change';
                $(elementSelector).on(filterActions, function () {
                    iris_validator.filters['__all'](this);
                    iris_validator.recaptchaRender();
                });
                if (typeof (iris_validator.filters[elementName]) == 'function') {
                    $(elementSelector).on(filterActions, function () {
                        iris_validator.filters[$(this).attr('name')](this);
                    });
                }
                if (typeof (iris_validator.validators[elementName]) == 'function') {
                    $(elementSelector).on('blur change', function () {
                        if($(this).attr('type') !== 'file') {
                            $(this).val($(this).val().trim());
                        }
                        iris_validator.validators[$(this).attr('name')](this);
                    });
                    $(elementSelector).on(filterActions, function () {
                        var elementName = $(this).attr('name');
                        var iris_validator = $(this).parents('form').data('irisFormValidator');
                        var elementSelector = iris_validator.formSelector + ' [name="' + elementName + '"]';
                        if (iris_validator.validateByTimeout.indexOf(elementName) == -1) {
                            return;
                        }
                        if (iris_validator.validateTimeouts[elementName]) {
                            clearTimeout(iris_validator.validateTimeouts[elementName]);
                        }
                        iris_validator.validateTimeouts[elementName] = setTimeout(function (elementName) {
                            iris_validator.validators[elementName]($(elementSelector));
                        }, 1000, elementName);
                    });
                }
                if ($(elementSelector).prop('required')) {
                    $(elementSelector).on(filterActions, function () {
                        iris_validator.filters['__required'](this);
                    });
                    $(elementSelector).on('blur', function () {
                        $(this).val($(this).val().trim());
                        iris_validator.validators['__required'](this);
                    });
                    $(elementSelector).on(filterActions, function () {
                        var elementName = $(this).attr('name');
                        var iris_validator = $(this).parents('form').data('irisFormValidator');
                        var elementSelector = iris_validator.formSelector + ' [name="' + elementName + '"]';
                        if (iris_validator.validateByTimeout.indexOf(elementName) == -1) {
                            return;
                        }
                        if (iris_validator.validateTimeouts[elementName]) {
                            clearTimeout(iris_validator.validateRequiredTimeouts[elementName]);
                        }
                        iris_validator.validateRequiredTimeouts[elementName] = setTimeout(function (elementName) {
                            iris_validator.validators['__required']($(elementSelector));
                        }, 1000, elementName);
                    });
                }
            }
        },
        init: function (settings) {
            iris_validator.applySettings(settings);
            iris_validator.applyBaseFunctions();
            iris_validator.applyFiltersValidators();
            if (iris_validator.focusFirstInput) {
                $(iris_validator.formSelector).find(iris_validator.classToSelector(iris_validator.formElementClass)).first().trigger('focus');
            }
            $(iris_validator.formSubmitButton).on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                iris_validator.submitForm();
            });
        },
        recaptchaRender: function (force) {
            var force = force || false;
            if (typeof (grecaptcha) === 'undefined' || !iris_validator.recaptchaAdded) {
                $.getScript('https://www.google.com/recaptcha/api.js?onload=recaptcha2ValidatorOnload&render=explicit', function(){
                    iris_validator.recaptchaAdded = true;
                });
            } else if (force) {
                recaptcha2ValidatorOnload();
            }
        },
        recaptcha2Callback: function (token) {
            $(iris_validator.tokenSelector).val(token).trigger('change');
            iris_validator.showResult('', true);
            setTimeout(function () {
                $($(activeFormSelector).data('irisFormValidator').formSubmitButton).trigger('focus');
            }, 1000);
        },
        submitForm: function () {
            for (var i in iris_validator.formArray) {
                var elementSelector = iris_validator.formSelector + ' [name="' + iris_validator.formArray[i]['name'] + '"]';
                $(elementSelector).trigger('change');
                $(elementSelector).trigger('blur');
            }

            setTimeout(function () {
                if (!$(iris_validator.formSubmitButton).hasClass('disabled')) {
                    iris_validator.disableSubmit();
                    var formData = iris_validator.getFormData();
                    iris_validator.sendDataToServer(formData);
                }
            }, 1000);
        },
        getFormData: function () {
            var formData = new FormData();
            for (var i in iris_validator.formArray) {
                if (iris_validator.formArray[i]['name'] === iris_validator.phoneInputName) {
                    formData.append(iris_validator.formArray[i]['name'], iris_validator.destroyMask($(activeFormSelector + ' [name="'+iris_validator.phoneInputName+'"]').val()));
                } else if ($(activeFormSelector + ' [name="' + iris_validator.formArray[i]['name'] + '"]').attr('type') === 'file') {
                    formData.append(iris_validator.formArray[i]['name'], $(activeFormSelector + ' [name="' + iris_validator.formArray[i]['name'] + '"]').prop('files')[0]);
                } else {
                    formData.append(iris_validator.formArray[i]['name'], $(activeFormSelector + ' [name="' + iris_validator.formArray[i]['name'] + '"]').val().trim());
                }
            }
            return formData;
        },
        showLoader: function () {
            if (!$(iris_validator.formSubmitButton).data('textBkp')) {
                $(iris_validator.formSubmitButton).data('textBkp', $(iris_validator.formSubmitButton).html());
            }
            $(iris_validator.formSubmitButton).html('<span class="fa fa-spinner spinning"></span> Loading');
            return this;
        },
        hideLoader: function () {
            $(iris_validator.formSubmitButton).html($(iris_validator.formSubmitButton).data('textBkp'));
            $(iris_validator.formSubmitButton).removeData('textBkp');
            return this;
        },
        showCaptcha: function () {
            $(iris_validator.formSelector).find('.g-recaptcha-cont').removeClass('hidden');
            return this;
        },
        hideCaptcha: function () {
            $(iris_validator.formSelector).find('.g-recaptcha-cont').addClass('hidden');
            return this;
        },
        disableSubmit: function () {
            $(iris_validator.formSubmitButton).addClass('disabled');
            return this;
        },
        enableSubmit: function () {
            $(iris_validator.formSubmitButton).removeClass('disabled');
            return this;
        },
        showResult: function (text, success) {
            $(iris_validator.formSelector + ' .text-warning').removeClass('has-sucess').removeClass('has-error').text('').append(text).addClass(success ? 'has-sucess' : 'has-error');
            return this;
        },
        sendDataToServer: function (formData) {
            iris_validator.showLoader().disableSubmit().hideCaptcha();
            submitedFormSelector = iris_validator.formSelector;
            $.ajax({
                url: iris_validator.url,
                data: formData,
                method: 'post',
                cache: false,
                contentType: false,
                processData: false,
                dataType: 'json',
                success: function (data) {
                    var formObject = $(submitedFormSelector).data('irisFormValidator');
                    switch (data.code) {
                        case "1":
                            formObject.success(data);
                            break;
                        case "5":
                            formObject.showError($(formObject.tokenSelector), data.msg, '_validator');
                            formObject.recaptchaRender(true);
                            formObject.showCaptcha().enableSubmit().hideLoader();
                            break;
                        case "7":
                            if (formObject.phoneInputName) {
                                formObject.showError($(formObject.formSelector + ' [name="' + formObject.phoneInputName + '"]'), data.msg, '_validator');
                            } else {
                                formObject.showResult(data.msg, false);
                            }
                            formObject.hideLoader().enableSubmit();
                            break;
                        default:
                            if (data.msg.length) {
                                formObject.showResult(data.msg, false);
                            }
                            if (data.errors.length > 0) {
                                $.each(data.errors, function (fKey, fValue) {
                                    formObject.showError($(formObject.formSelector + ' [name="' + fKey + '"]'), fValue, '_validator')
                                });
                            }
                            formObject.enableSubmit().hideLoader();
                            break;

                    }
                },
                error: function (data) {
                    var formObject = $(submitedFormSelector).data('irisFormValidator');
                    formObject.enableSubmit().hideLoader();
                    formObject.showResult(iris_validator.errorMessages.connectionError, false);
                },
            });
        }
    };
    iris_validator.init(settings);
    $(element).data('irisFormValidator', iris_validator);
    return iris_validator;
}
function recaptcha2ValidatorOnload() {
    var currentForm = $(activeFormSelector).data('irisFormValidator');
    var element = $(activeFormSelector).find('.g-recaptcha');
    var id = element.attr('id');
    var classNames = element.attr('class');
    $(element).replaceWith('<div class="' + classNames + '" id="' + id + '"></div>');
    var element = $(activeFormSelector).find('.g-recaptcha');
    grecaptcha.render($(element).attr('id'), {
        'sitekey': RCC,
        'theme': 'light',
        'callback': currentForm.recaptcha2Callback,
    });
    currentForm.showCaptcha();
}

